* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  font-size: 62.5%;
}

body {
  font-family: 'Open sans', sans-serif;
  font-size: 1.5rem;
  color: $mainColor;
  z-index: 1;
}

.container {
  max-width: 1020px;
  width: 100%;
  padding: 0 10px;
  margin: 0 auto;
  @media screen and (min-width: 370px) {
      padding: 0 15px;
  }
}

.text {
  font-size: 1.4rem;
  line-height: 2.5rem;
  color: $mainColor;

  &--smaller {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }

  &--bigger {
    font-size: 1.7rem;
    line-height: 2.5rem;
  }

  &--grey {
    color: $grey;
  }

  &--accented {
    color: $green;
  }
}

.link {
  font-size: 1.4rem;
  font-weight: 500;
  color: $mainColor;
  text-decoration: none !important;
  transition: color 0.5s ease;
  &--grey {
    color: $grey;
  }
  &:hover {
    color: $green;
  }
}

.nav-link {
  color: $green;
  font-size: 1.4rem;
  text-decoration: none;
  font-weight: 300;
  transition: color 0.5s ease;
  &:hover {
    color: $green;
  }

  &--active {
    font-weight: 300;
  }

  &__container {
    display: none;
    @media #{$tablet} {
      display: block;
      padding-top: 40px;
    }
  }
}

.header {
  font-weight: 300;
  color: $mainColor;
  font-size: 3rem;
  text-align: center;
}

.decor {
  display: block;
  margin: 30px auto 30px auto;
  @media #{$desktop} {
    margin: 25px auto 45px auto;
  }
}

.accent-hr {
  border-color: $green;
  margin: 30px 0;
}

.button {
  border: 1px solid $green;
  border-radius: 15px;
  box-shadow: none;
  background-color: transparent;
  color: $mainColor;
  font-size: 1.5rem;
  line-height: 2.5rem;
  cursor: pointer;
  transition: all 0.5s ease;
  outline: none;
  &:hover {
    background-color: rgba(11, 212, 196, 0.8);
    font-weight: 600;
    color: $grey;
  }
  &:focus {
    box-shadow: none;
    outline: none;
    -webkit-appearance: none;
    background-color: rgba(11, 212, 196, 0.9);
    font-weight: 600;
    color: $grey;
  }
}

.list {
  font-size: 1.5rem;
  padding-left: 2.5rem;
}

h2 {
    color: $green;
    font-size: 2.4rem;
    font-weight: 300;
}

.hidden {
  display: none;
}

.db-info, .help-block {
  display: block;
  background-color: $grey;
  color: $red;
  font-size: 1.4rem;
  line-height: 2.5rem;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  &--green {
    color: $green;
  }
}

.help-inline {
  font-size: 1.4rem;
  text-align: center;
  color: $red;
  display: block;
  // &--green {
  //   font-weight: 600;
  //   color: $green;
  // }
}

.main-page-decor {
  display: none;
  @media #{$desktop} {
    display: block;
    position: absolute;
    z-index: 10;
  }
}

.reset-info {
  background-color: $grey;
  padding-top: 100px;
  padding-bottom: 100px;
  height: 60vh;
}

.alert {
  background-color: $grey;
  color: $red;
  font-size: 1.4rem;
  line-height: 2.5rem;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  padding-top: 120px;
  padding-bottom: 150px;
  height: 60vh;
  margin: 0;
  border: none;
}
.alert-success {
  color: $green;
}

.alert-red {
  color: $red;
}
