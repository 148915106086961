$background-color: #444446;
$font-color: #FFF;
$blue-color: #0BD4C4;

.reservation {
  background: $grey;
}

.reservation__mentor-column {
  display: flex;
  // height: 200px;
  height: auto;
  margin-top: 25px;
  margin-bottom: 60px;
}

.reservation__container {
  padding: 60px 0;
  .col-md-4 {
    padding-bottom: 60px;
  }
  .justify-content-md-center {
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

}

.reservation__header {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  padding: 40px 0;
  position: relative;

  &:after {
    content: " ";
    display: block;
    width: 40px;
    height: 2px;
    bottom: 15px;
    background: #0BD4C4;
    position: absolute;
    left: calc(50% - 25px);
  }
}

.reservation__rating {
  margin-bottom: 15px;
}

.reservation__mentor-description {
  width: 50%;
  height: 160px;

  h2 {
    font-size: 20px;
    font-weight: 600;
    color: #00d0c1;
    margin-bottom: 15px;
  }

  p {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .reservation__mentor-text {
    margin-bottom: 0;
    line-height: 1.2;
    font-weight: 600;
    // height: 150px;
  }

  .reservation__button-container {
    margin-top: 10px;
    color: #c7c7c7;

    p {
      margin: 0;
      font-size: 11px;
      font-weight: 600;
    }
  }

  .active {
    color: $blue-color;
    line-height: 1.4;
  }

  button {
    margin-top: 10px;
    border: 1px solid $blue-color;
    border-radius: 20px;
    background: none;
    box-shadow: none;
    padding: 3px 30px;
    font-size: 12px;
    font-weight: 600;
    color: white;
  }
}

.reservation__mentor-image {
  width: 50%;
  align-self: center;
  border-radius: 100%;
  margin-right: 20px;
  overflow: hidden;
  img {
    width: 100%;
    max-width: 140px;
  }
}

.reservation__filter {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  p {
    top: 40px;
    right: 40px;
    font-size: 14px;
    color: #999999;
    font-weight: 600;
    width: 298px;
    text-align: center;
    @media #{$desktop} {
      position: absolute;
      text-align: right;
      width: 21%;
    }
  }

  strong {
    font-size: 12px;
    color: #FFF;
    font-weight: 600;
    padding-left: 20px;
    display: inline-block;
    position: relative;

    &:after {
      content: "";
      display: block;
      position: absolute;
      right: -20px;
      top: 7px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 7px solid $blue-color;
    }
  }
}


.reservation__dropdown {
  right: 20px;
  top: 70px;
  background: #4a4a4c;
  list-style: none;
  z-index: 1;
  overflow: overlay;
  max-height: 150px;
  width: 290px;
  &::-webkit-scrollbar {
    background-color: $grey;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $green;
  }

  li {
    font-size: 12px;
    padding: 10px 20px;

    &:last-child {
      margin: 0;
    }

    &:hover {
      background: $blue-color;
      color: #2d2d2d;
      cursor: pointer;
    }
  }
  @media #{$desktop} {
    position: absolute;
    width: 200px;
  }
}

.reservation__mentor-text-container {
  // height: 140px;
}
