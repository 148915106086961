.main-page-top {
  background-color: $grey;
  padding-bottom: 10px;
  // padding-top: 30px;
  @media #{$desktop} {
    // padding-top: 80px;
    padding-bottom: 20px;
  }

  .container {
    position: relative;
  }

  &__header {
    font-weight: 300;
    padding-top: 30px;
    margin-bottom: 34px;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;

    @media #{$desktop} {
      padding-top: 80px;
    }
  }

  &__subheader {
    font-size: 1.3rem;
    text-align: center;
    color: $green;
    margin-bottom: 35px;
  }

  &__form {
    position: relative;
    width: 80%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }

  &__email-input {
    outline: none;
    display: block;
    width: 100%;
    border-radius: 30px;
    height: 60px;
    font-size: 1.2rem;
    box-shadow: none;
    padding-left: 19px;
    border: 2px solid $green;
    background-color: transparent;
    color: $mainColor;
    font-size: 1.2rem;

    &::-webkit-input-placeholder {
      color: $mainColor;
    }

    &::-moz-placeholder {
      color: $mainColor;
    }

    &:-ms-input-placeholder {
      color: $mainColor;
    }

    &:-moz-placeholder {
      color: $mainColor;
    }
    @media #{$phoneM} {
      padding-left: 39px;
    }
  }

  &__email-input-sent {
    position: absolute;
    top: 0;
    right: 0;
    width: 40%;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    height: 60px;
    font-size: 1.2rem;
    box-shadow: none;
    color: $grey;
    font-weight: 600;
    background-color: $green;
    border: none;
    transition: all 0.5s ease;
    cursor: pointer;

    &:hover {
      background-color: transparent;
      border: 2px solid $green;
      @media #{$phoneM} {
        font-size: 1.4rem;
      }
    }
  }
  &__select-container {
    position: relative;
    width: 60%;
    // max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }

  &__select {
    width: 40%;
    // max-width: 400px;
    outline: none;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 100%;
    border-radius: 30px;
    height: 60px;
    font-size: 1.2rem;
    box-shadow: none;
    padding-left: 10px;
    padding-right: 45px;
    border: 2px solid $green;
    background-color: $grey;
    color: $mainColor;
    font-size: 1.2rem;
    -webkit-appearance: none;
    &::-webkit-input-placeholder {
      color: $mainColor;
    }
    &::-moz-placeholder {
      color: $mainColor;
    }
    &:-ms-input-placeholder {
      color: $mainColor;
    }
    &:-moz-placeholder {
      color: $mainColor;
    }
    @media #{$desktop} {
      padding-left: 39px;
    }
    .select-text-desktop {
      display: none;
      @media (min-width: 675px) {
        display: block;
      }
    }
    .select-text-mobile {
      @media (min-width: 675px) {
        display: none;
      }
    }

    option {
      font-size: 1.2rem;
      width: 150px;
    }
  }

  &__select-btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 40%;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    height: 60px;
    font-size: 1.2rem;
    box-shadow: none;
    color: $grey;
    font-weight: 600;
    background-color: $green;
    border: none;
    transition: all 0.5s ease;

    &:hover {
      background-color: transparent;
      border: 2px solid $green;
      font-size: 1.6rem;
    }
  }

  &__img-container {
    // width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
    @media #{$desktop} {
      width: 100%;
      height: auto;
      padding-top: 30px;
      padding-bottom: 50px;
    }
  }
  &__img {
      display: block;
      width: 100%;
      height: auto;
      max-width: 435px;
      max-height: auto;
      @media #{$desktop} {
        display: none;
      }
      &--bigger {
        display: none;
        @media #{$desktop} {
          display: block;
          width: 100%;
          height: auto;
          max-width: 511px;
          max-height: auto;
        }
      }
  }
  &__footer-decor {
    transform: rotate(180deg);
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
  }
  &__decor {
    display: none;
    @media #{$desktop} {
      display: block;
      position: absolute;
      z-index: 10;
    }
  }
  &__decor-1 {
    top: 230px;
    right: -20px;
    width: 180px;
  }
  &__decor-2 {
    top: 285px;
    left: 112px;
    width: 49px;
  }
  &__decor-3 {
    top: 397px;
    left: -80px;
    width: 118px;
  }
  &__decor-4 {
    top: 590px;
    left: -80px;
    width: 89px;
  }
  &__decor-5 {
    top: 500px;
    right: 200px;
    width: 79px;
  }
  &__decor-6 {
    top: 559px;
    right: 30px;
    width: 110px;
  }
  &__decor-7 {
    top: 309px;
    right: 130px;
    width: 59px;
  }
}

.main-page-how-it-works {
  background-color: $mainColor;

  &__flex-container {
    display: flex;
    flex-direction: column;
    @media #{$desktop} {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__text-container {
    text-align: center;
    .decor {
      @media #{$desktop} {
        margin-left: 0;
      }
    }
    @media #{$desktop} {
      padding-left: 20px;
      flex: 0.3;
    }
  }

  &__steps-container {
    padding-bottom: 40px;
    @media #{$desktop} {
      flex: 0.7;
      display: flex;
      flex-direction: row;
      padding-bottom: 0;
    }
  }

  &__heading {
    color: $grey;
    padding-top: 60px;
    padding-bottom: 1px;
    font-size: 3rem;
    font-weight: 600;
    text-align: center;
    @media #{$desktop} {
      padding-top: 100px;
      text-align: left;
    }
  }

  &__decor {
    margin: 19px auto;
  }

  &__step {
    @media #{$desktop} {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 33%;
      position: relative;
    }
  }

  &__step-number {
    position: relative;
    color: $green;
    font-size: 4.3rem;
    line-height: 69px;
    font-weight: 400;
    width: 67px;
    height: 67px;
    border: 1px solid $mainColor;
    box-shadow: 0 0 0 7px rgba(230, 230, 230, 0.4);
    border-radius: 50%;
    text-align: center;
    margin: 20px auto;
    @media #{$desktop} {
      width: 100px;
      height: 100px;
      font-size: 7.3rem;
      line-height: 100px;
    }

    &::after {
      background: none;
      border: 1px solid rgba(230, 230, 230, 0.6);
      border-radius: 50%;
      content: "";
      display: block;
      position: absolute;
      top: -12px;
      left: -12px;
      right: -12px;
      bottom: -12px;
      pointer-events: none;
    }
  }

  &__arrow {
    display: block;
    margin: 0 auto;
    width: 19px;
    @media #{$desktop} {
      width: 22px;
      display: inline;
      position: absolute;
      top: 36%;
      right: -9px;
      transform: rotate(270deg);
    }
    &--last {
      @media #{$desktop} {
        display: none;
      }
    }
  }

  &__subheader {
    color: $green;
    font-size: 2rem;
    margin-top: 13px;
    margin-bottom: 13px;
    text-align: center;
    font-weight: 400;
    @media #{$desktop} {
      padding-top: 35px;
      width: 64%;
      margin-top: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .text {
    width: 85%;
    color: $lightGrey;
    margin-bottom: 10px;
    max-width: 428px;
    margin-left: auto;
    margin-right: auto;
    @media #{$desktop} {
      margin-bottom: 70px;
      text-align: left;
      width: 80%;
      margin-left: 0;
    }
  }
}

.main-page-more-about {
  background-color: $platinium;
  color: $grey;

  &__link {
    display: block;
    text-transform: uppercase;
    color: $grey;
    font-size: 1.2rem;
    line-height: 2.2rem;
    padding-top: 42px;
    padding-bottom: 42px;
    text-align: center;
    @media #{$desktop} {
      font-size: 1.8rem;
    }
  }
}

.main-page-why-us {
  background-color: $green;
  background: rgba(0,198,196,1);
  background: -moz-linear-gradient(left, rgba(0,198,196,1) 0%, rgba(0,198,196,1) 15%, rgba(11,212,196,1) 50%, rgba(0,198,196,1) 90%, rgba(0,198,196,1) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0,198,196,1)), color-stop(15%, rgba(0,198,196,1)), color-stop(50%, rgba(11,212,196,1)), color-stop(90%, rgba(0,198,196,1)), color-stop(100%, rgba(0,198,196,1)));
  background: -webkit-linear-gradient(left, rgba(0,198,196,1) 0%, rgba(0,198,196,1) 15%, rgba(11,212,196,1) 50%, rgba(0,198,196,1) 90%, rgba(0,198,196,1) 100%);
  background: -o-linear-gradient(left, rgba(0,198,196,1) 0%, rgba(0,198,196,1) 15%, rgba(11,212,196,1) 50%, rgba(0,198,196,1) 90%, rgba(0,198,196,1) 100%);
  background: -ms-linear-gradient(left, rgba(0,198,196,1) 0%, rgba(0,198,196,1) 15%, rgba(11,212,196,1) 50%, rgba(0,198,196,1) 90%, rgba(0,198,196,1) 100%);
  background: linear-gradient(to right, rgba(0,198,196,1) 0%, rgba(0,198,196,1) 15%, rgba(11,212,196,1) 50%, rgba(0,198,196,1) 90%, rgba(0,198,196,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr= '#00c6c4', endColorstr='#00c6c4', GradientType=1 );

  &__mobile {
    @media #{$desktop} {
      display: none;
    }
  }

  .container {
    position: relative;
  }

  &__subheader {
    color: $mainColor;
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: 60px;
    text-align: center;
  }

  &__img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 50px;
    margin-bottom: 14px;
  }

  &__table {
    width: 100%;

    td {
      padding-left: 0;
      padding-right: 0;
      vertical-align: top;
    }

    .table-left {
      width: 50%;
      text-align: right;
    }

    .table-right {
      width: 50%;
      text-align: left;
    }

    .padding-left {
      padding-left: 20px;
    }

    .padding-right {
      padding-right: 20px;
    }

    .text {
      font-size: 1.2rem;
      line-height: 1.4rem;
      padding-bottom: 19px;
    }
  }

  &__sub-subheader {
    font-size: 1.4rem;
    margin-bottom: 1px;
    font-weight: 600;
    @media #{$desktop} {
      margin-bottom: 4px;
    }
  }

  &__decor {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 30px;
    padding-top: 10px;
    padding-bottom: 20px;
  }

  &__desktop {
    display: none;
    @media #{$desktop} {
      display: block;
    }
    .main-page-why-us__subheader {
      display: inline;
      font-size: 2.8rem;
      margin-top: 24px;
      margin-bottom: 0;
    }
  }
  &__desktop-flex-container {
    display: flex;
    flex-direction: row;
  }
  &__left-container {
    flex: 0.3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .text, .main-page-why-us__sub-subheader {
      text-align: right;
      padding-right: 40px;
      margin-left: 0;
      margin-right: auto;
    }
    .main-page-why-us__sub-container {
      img {
        right: -10px;
      }
    }
  }
  &__big-logo-container {
    flex: 0.4;
    display: flex;
    justify-content: space-around;
    // justify-content: center;
    align-items: center;
  }
  &__bracket {
    height: 530px;
    width: auto;
    padding-top: 116px;
    padding-bottom: 116px;
  }
  &__small-logo-container {
    display: inline-flex;
    flex-direction: column;
    img {
      width: 124px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
  &__right-container {
    flex: 0.3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .text, .main-page-why-us__sub-subheader{
      margin-right: 0;
      margin-left: auto;
      width: 100%;
      padding-left: 40px;
    }
    img {
      left: -20px;
    }
  }
  &__sub-container {
    position: relative;
    margin-top: 40px;
    margin-bottom: 40px;
    height: 70px;
    .text {
      width: 100%;
      line-height: 1.7rem;
    }
    img {
      display: inline;
      position: absolute;
      top: 0;
      right: 0
    }
  }

  &__left-center {
    padding-right: 30px;
    img {
      right: -10px;
      padding-right: 15px;
    }
  }
  &__left-bottom {
    .left-bottom-img {
      right: -26px!important;
    }
  }
  &__right-center {
    padding-left: 30px;
    img {
      left: 0;
      padding-left: 10px;
    }
  }
  &__decor-1 {
    width: 107px;
    left: -100px;;
    top: 398px;
  }
  &__decor-2 {
    width: 157px;
    right: -60px;;
    top: 498px;
  }
}

.main-page-mentors {
  &__flex-container {
    @media #{$desktop} {
      display: flex;
      flex-direction: row;
    }
  }
  &__text-container {
    @media #{$desktop} {
      order: 2;
      flex: 0.4;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  &__mentor-continer {
    .reservation__mentor-description {
      .reservation__mentor-text {
        font-weight: 300;
      }
    }
    @media #{$desktop} {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      order: 1;
      flex: 0.6;
    }
  }
  &__heading {
    color: $grey;
    padding-top: 60px;
    padding-bottom: 1px;
    font-size: 3rem;
    font-weight: 600;
    text-align: center;
    @media #{$desktop} {
      padding-top: 10px;
      text-align: left;
    }
  }
  .text {
    width: 85%;
    color: $lightGrey;
    margin-bottom: 30px;
    max-width: 428px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    @media #{$desktop} {
      text-align: left;
      margin-left: 0;
      // vertical-align: middle;
      // margin-bottom: 60px;
    }
  }
  .decor {
    @media #{$desktop} {
      margin-left: 0;
    }
  }
  .reservation__mentor-description {
    // padding-top: 20px;
    color: $lightGrey;
  }
  &__mentor-column {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding-bottom: 60px;
    @media #{$desktop} {
      width: 50%;
      padding-bottom: 0;
      height: 290px;
      margin-top: 95px;
      margin-bottom: 30px;
    }
  }
  &__mentor-image {
    img {
      width: 130px;
      padding-bottom: 20px;
      filter: grayscale(1);
    }
  }
}

.main-page-about-us {
  background-color: $grey;
  .container {
    position: relative;
  }
  &__flex-container {
    @media #{$desktop} {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }

  &__text-container {
    @media #{$desktop} {
      flex: 0.4;
    }
  }
  &__heading {
    color: $mainColor;
    padding-top: 60px;
    padding-bottom: 1px;
    font-size: 3rem;
    font-weight: 600;
    text-align: center;
    @media #{$desktop} {
      text-align: left;
    }
  }
  &__img-container {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
    @media #{$desktop} {
      flex: 0.6;
      margin-right: 0;
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }
  &__img {
      display: block;
      width: 100%;
      height: auto;
      max-width: 435px;
      max-height: auto;
      @media #{$desktop} {
      display: none;
      }
      &--bigger {
        display: none;
        @media #{$desktop} {
          display: inline;
          width: 85%;
          height: auto;
          max-width: 846px;
          // position: relative;
          // right: -100px;
        }
      }
  }
  &__footer-decor {
    transform: rotate(180deg);
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
  }
  .text {
    width: 85%;
    color: $lightGrey;
    margin-bottom: 10px;
    max-width: 428px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    @media #{$desktop} {
      text-align: left;
      margin-left: 0;
    }
  }
  .decor {
    @media #{$desktop} {
      margin-left: 0;
    }
  }
  &__decor-1 {
    width: 93px;
    left: -30px;
    top: -50px;
  }
  &__decor-2 {
    width: 90px;
    right: 80px;
    top: 409px;
  }
}

.main-page-contact {
  @media #{$desktop} {
    background-image: url("../img/map.png");
  }
  &__heading {
    color: $grey;
    padding-top: 60px;
    padding-bottom: 1px;
    font-size: 3rem;
    font-weight: 600;
    text-align: center;
    @media #{$desktop} {
      padding-top: 130px;
    }
  }
  &__link {
    display: block;
    color: $green;
    text-decoration: none;
    text-align: center;
    font-size: 1.6rem;
    margin-top: 10px;
    padding-bottom: 30px;
    transition: all 0.5s ease;
    &:hover {
      font-weight: 600;
      font-size: 1.7rem;
    }
  }
  &__container {
    @media #{$desktop} {
      width: 480px;
      height: 472px;
      margin-left: auto;
      margin-right: auto;
      background-color: $mainColor;
    }
  }
  .text {
    color: $lightGrey;
    margin-bottom: 10px;
    text-align: center;
  }
}


.main-page-top__select-container {
  position: relative;

  p {
    font-size: 14px;
    line-height: 52px;
    font-weight: 300;
    padding-left: 10px;
  }

  ul {
    list-style: none;
    position: absolute;
    top: 52px;
    left: 0px;
    overflow: overlay;
    background: #4a4a4c;
    width: 95%;
    max-height: 220px;
    &::-webkit-scrollbar {
      background-color: $grey;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $green;
    }
    li {
      font-size: 14px;
      font-weight: 300;
      padding-left: 10px;
      padding: 15px 20px;
      overflow: hidden;

      &:first-child {
        margin-top: 15px;
      }

      &:hover {
        background: #0bd4c4;
        cursor: pointer;
      }
    }
    @media #{$tablet} {
      max-height: 180px;
      left: 33px;
      width: 54.5%;
    }
  }
}
