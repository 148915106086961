.regulations {
  background-color: $grey;
  padding-bottom: 85px;
  @media #{$desktop} {
    padding-bottom: 90px;
  }
  padding-top: 50px;
  @media #{$desktop} {
    padding-top: 80px;
  }
  &__subheading {
    display: block;
    font-size: 2rem;
    text-align: center;
    line-height: 3rem;
    text-transform: uppercase;
    font-weight: 600;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  &__main-list {
    list-style: none;
  }
  &__list {
    padding-left: 20px;
    margin-bottom: 20px;
  }
  &__list-element {
    padding-bottom: 15px;
    font-size: 1.4rem;
    line-height: 2.5rem;
    strong {
      padding-bottom: 15px;
      font-size: 1.4rem;
      line-height: 2.5rem;
    }
  }
  .text {
    font-size: 1.4rem;
  }
}
