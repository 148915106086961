$blue: #00d0c1;

.header {
  background-color: $grey;
  width: 100%;

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px;
  }

  &__logo-container {
    @media #{$desktop} {
      flex: 0.2;
    }
  }

  &__menu-container {
    display: none;
    @media #{$desktop} {
      display: block;
      flex: 0.6;
    }
  }

  &__logo-img {
    width: 180px;
    @media #{$desktop} {
      width: 203px;
    }
  }

  &__mobile-menu-icon {
    width: 37px;
    cursor: pointer;
    @media #{$desktop} {
      display: none;
    }
  }

  &__navigation {
    display: none;
    @media #{$desktop} {
      display: block;
      flex: 0.6;
    }
  }

  &__verical-menu {
    display: none;
    @media #{$desktop} {
      list-style: none;
      display: block;

      .link {
        transition: all 0.5s ease;
        box-sizing:content-box;
        position: relative;

        &:hover {
          color: $green;
          transition: all 0.5s ease;
          &:before {
            content: '{';
              position: absolute;
            top: -3px;
            left: -8px;
          }

          &:after {
            content: '}';
            position: absolute;
            top: -3px;
            right: -8px;
          }
        }
      }
    }
  }

  &__list-element {
    display: inline-block;
    margin: 0 15px;
  }

  &__user-info {
    display: none;
    margin-bottom: 0;
    @media #{$desktop} {
      display: block;
      flex: 0.2;
      text-align: right;
      .text {
        display: inline-block;
        margin-left: 17px;
        vertical-align: middle;
        max-width: 200px;
        font-weight: 400;
        margin-bottom: 0;
      }
    }
  }

  ul {
    margin: 0;
  }
}

.mobile-menu {
  // display: none;
  &__container {
    display: none;
    position: fixed;
    top: 0;
    z-index: 50;
    width: 100%;
    background-color: $grey;
  }

  .settings-text {
    display: inline-block;
    font-size: 1.6rem;;
    color: white;
    margin-left: 20px;
    font-weight: 300;
  }

  &__close-icon {
    position: absolute;
    top: 30px;
    right: 15px;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  &__list {
    list-style: none;
    text-align: left;
    padding-top: 70px;
    padding-left: 40px;
    padding-bottom: 10px;
  }
  &__list-element {
    margin-bottom: 36px;
    .link {
      font-size: 2.2rem;
      font-weight: 100;
    }
  }
  &__user-info {
    .text {
      color: $green;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 14px;
      width: 70%;

      padding-left: 40px;
      padding-top: 32px;

      @media screen and #{$tablet} {
        font-size: 2.2rem;
      }
    }
  }
  &__settings-image {
    width: 21px;
    height: 21px;
    margin-left: 40px;
    margin-bottom: 50px;
    display: inline-block;
    vertical-align: top;

    // @media screen and #{$tablet} {
    //   disp
    // }
  }
}

.header__user-logout {
  color: white;

  &:hover {
    color: white;
  }
}

.header__button {
  border: 1px solid $blue;
  border-radius: 20px;
  background: none;
  box-shadow: none;
  padding: 6px 30px;
  font-size: 1.4rem;
  font-weight: 400;
  color: white;
  transition: all 0.5s ease;
  width: 110px;
  cursor: pointer;
  &:hover {
    border-color: $green;
    color: $grey;
    background-color: $green;
    font-weight: 600;
  }
  &:focus {
    outline: none;
  }
}

.header__button-mobile {
  margin-left: 40px;
  margin-bottom: 40px;
  cursor: pointer;
  &:hover {
    border-color: $green;
    color: $grey;
    background-color: $green;
    font-weight: 600;
  }
}
