.registration__container {
  background: $grey;
  font-size: 1.4rem;
  padding: 0 0 80px;

  label {
    font-size: 1.4rem;
    width: 60%;
    display: block;
    margin: auto;
  }

  .help-inline {
    font-size: 1.4rem;
    text-align: center;
    color: $red;
    display: block;
  }
}

.registration__form-wrapper {
  max-width: 600px;
  margin: auto;
  padding-top: 50px;
}

.registration__header {
  padding-top: 40px;
  @media #{$desktop} {
    padding-top: 80px;
  }
}

.register__input {
  background: lighten(black, 20%);
  border: none;
  width: 60%;
  display: block;
  margin: 10px auto 20px;
  font-size: 1.4rem;;
  border-radius: 10px;
  color: white;
  padding: 5px 10px;
}

.register__buttons-container {
  text-align: center;
  margin: 20px 0;
}

.register__back-button {
  font-size: 1.4rem;
  color: white;
  margin-right: 40px;
}

.register__success-button {
  border: 1px solid $blue;
  border-radius: 20px;
  background: none;
  box-shadow: none;
  padding: 6px 30px;
  font-size: 1.4rem;
  font-weight: 400;
  color: white;
  transition: all 0.5s ease;
  width: 130px;
  cursor: pointer;
  &:hover {
    border-color: $green;
    color: $grey;
    background-color: $green;
    font-weight: 600;
  }
  &:focus {
    outline: none;
  }
}

.registration__control-container {
  border: 1px solid $blue-color;
  // Blue?
  width: 90%;
  max-width: 300px;
  margin: auto;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  margin-bottom: 40px;

  button {
    background: none;
    box-shadow: none;
    border: 0;
    padding: 3px 30px;
    font-size: 14px;
    width: 50%;
    font-weight: 600;
    color: $mainColor;
    cursor: pointer;
    transition: all 0.5s ease;
    &:hover {
      border-color: $green;
      background-color: $green;
      font-weight: 600;
    }
  }

  #person {
    border-right: 1px solid $blue-color;
  }

  .active {
    background: $blue-color;
  }
}


.registration__checkbox-container {
  width: 60%;
  margin: auto;

  input,
  label {
    display: inline-block;
    width: auto;
  }

  label {
    padding-left: 20px;
  }
}


.registration__checkbox-container {
  font-size: 1.4rem;
  display: flex;
  width: 60%;
  margin: 30px auto;

  label {
    font-size: 1.4rem;
    color: white;
    padding-left: 10px;
    margin: 0;
  }

  a {
    font-size: 1.4rem;
    // color: darken(#FFF, 20%);
  }
}

.login-checkbox {
  input {
    margin: 3px 0;
    border: 1px solid $green;
    width: 15px;
    height: 15px;
    &:active {
      background-color: $grey;
    }
  }
}

.header__user-info {
  a {
    display: inline-block;
    color: $taupeGrey;
  }
}

#forgotten-password {
  width: 160px;
  display: block;
  margin: 40px auto;
}

#forgotten-password-email {
  width: 90%;
  max-width: 360px;
}

#forgotten-password-submit {
  width: 140px;
  display: block;
  margin: 20px auto;
}
