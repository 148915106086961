* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  font-size: 62.5%;
}

body {
  font-family: 'Open sans', sans-serif;
  font-size: 1.5rem;
  color: white;
  z-index: 1;
}

.container {
  max-width: 1020px;
  width: 100%;
  padding: 0 10px;
  margin: 0 auto;
}

@media screen and (min-width: 370px) {
  .container {
    padding: 0 15px;
  }
}

.text {
  font-size: 1.4rem;
  line-height: 2.5rem;
  color: white;
}

.text--smaller {
  font-size: 1.2rem;
  line-height: 1.5rem;
}

.text--bigger {
  font-size: 1.7rem;
  line-height: 2.5rem;
}

.text--grey {
  color: #4a4a4c;
}

.text--accented {
  color: #0bd4c4;
}

.link {
  font-size: 1.4rem;
  font-weight: 500;
  color: white;
  text-decoration: none !important;
  transition: color 0.5s ease;
}

.link--grey {
  color: #4a4a4c;
}

.link:hover {
  color: #0bd4c4;
}

.nav-link {
  color: #0bd4c4;
  font-size: 1.4rem;
  text-decoration: none;
  font-weight: 300;
  transition: color 0.5s ease;
}

.nav-link:hover {
  color: #0bd4c4;
}

.nav-link--active {
  font-weight: 300;
}

.nav-link__container {
  display: none;
}

@media (min-width: 768px) {
  .nav-link__container {
    display: block;
    padding-top: 40px;
  }
}

.header {
  font-weight: 300;
  color: white;
  font-size: 3rem;
  text-align: center;
}

.decor {
  display: block;
  margin: 30px auto 30px auto;
}

@media (min-width: 1016px) {
  .decor {
    margin: 25px auto 45px auto;
  }
}

.accent-hr {
  border-color: #0bd4c4;
  margin: 30px 0;
}

.button {
  border: 1px solid #0bd4c4;
  border-radius: 15px;
  box-shadow: none;
  background-color: transparent;
  color: white;
  font-size: 1.5rem;
  line-height: 2.5rem;
  cursor: pointer;
  transition: all 0.5s ease;
  outline: none;
}

.button:hover {
  background-color: rgba(11, 212, 196, 0.8);
  font-weight: 600;
  color: #4a4a4c;
}

.button:focus {
  box-shadow: none;
  outline: none;
  -webkit-appearance: none;
  background-color: rgba(11, 212, 196, 0.9);
  font-weight: 600;
  color: #4a4a4c;
}

.list {
  font-size: 1.5rem;
  padding-left: 2.5rem;
}

h2 {
  color: #0bd4c4;
  font-size: 2.4rem;
  font-weight: 300;
}

.hidden {
  display: none;
}

.db-info, .help-block {
  display: block;
  background-color: #4a4a4c;
  color: #EC644B;
  font-size: 1.4rem;
  line-height: 2.5rem;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
}

.db-info--green, .help-block--green {
  color: #0bd4c4;
}

.help-inline {
  font-size: 1.4rem;
  text-align: center;
  color: #EC644B;
  display: block;
}

.main-page-decor {
  display: none;
}

@media (min-width: 1016px) {
  .main-page-decor {
    display: block;
    position: absolute;
    z-index: 10;
  }
}

.reset-info {
  background-color: #4a4a4c;
  padding-top: 100px;
  padding-bottom: 100px;
  height: 60vh;
}

.alert {
  background-color: #4a4a4c;
  color: #EC644B;
  font-size: 1.4rem;
  line-height: 2.5rem;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  padding-top: 120px;
  padding-bottom: 150px;
  height: 60vh;
  margin: 0;
  border: none;
}

.alert-success {
  color: #0bd4c4;
}

.alert-red {
  color: #EC644B;
}

.header {
  background-color: #4a4a4c;
  width: 100%;
}

.header__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
}

@media (min-width: 1016px) {
  .header__logo-container {
    flex: 0.2;
  }
}

.header__menu-container {
  display: none;
}

@media (min-width: 1016px) {
  .header__menu-container {
    display: block;
    flex: 0.6;
  }
}

.header__logo-img {
  width: 180px;
}

@media (min-width: 1016px) {
  .header__logo-img {
    width: 203px;
  }
}

.header__mobile-menu-icon {
  width: 37px;
  cursor: pointer;
}

@media (min-width: 1016px) {
  .header__mobile-menu-icon {
    display: none;
  }
}

.header__navigation {
  display: none;
}

@media (min-width: 1016px) {
  .header__navigation {
    display: block;
    flex: 0.6;
  }
}

.header__verical-menu {
  display: none;
}

@media (min-width: 1016px) {
  .header__verical-menu {
    list-style: none;
    display: block;
  }
  .header__verical-menu .link {
    transition: all 0.5s ease;
    box-sizing: content-box;
    position: relative;
  }
  .header__verical-menu .link:hover {
    color: #0bd4c4;
    transition: all 0.5s ease;
  }
  .header__verical-menu .link:hover:before {
    content: '{';
    position: absolute;
    top: -3px;
    left: -8px;
  }
  .header__verical-menu .link:hover:after {
    content: '}';
    position: absolute;
    top: -3px;
    right: -8px;
  }
}

.header__list-element {
  display: inline-block;
  margin: 0 15px;
}

.header__user-info {
  display: none;
  margin-bottom: 0;
}

@media (min-width: 1016px) {
  .header__user-info {
    display: block;
    flex: 0.2;
    text-align: right;
  }
  .header__user-info .text {
    display: inline-block;
    margin-left: 17px;
    vertical-align: middle;
    max-width: 200px;
    font-weight: 400;
    margin-bottom: 0;
  }
}

.header ul {
  margin: 0;
}

.mobile-menu__container {
  display: none;
  position: fixed;
  top: 0;
  z-index: 50;
  width: 100%;
  background-color: #4a4a4c;
}

.mobile-menu .settings-text {
  display: inline-block;
  font-size: 1.6rem;
  color: white;
  margin-left: 20px;
  font-weight: 300;
}

.mobile-menu__close-icon {
  position: absolute;
  top: 30px;
  right: 15px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.mobile-menu__list {
  list-style: none;
  text-align: left;
  padding-top: 70px;
  padding-left: 40px;
  padding-bottom: 10px;
}

.mobile-menu__list-element {
  margin-bottom: 36px;
}

.mobile-menu__list-element .link {
  font-size: 2.2rem;
  font-weight: 100;
}

.mobile-menu__user-info .text {
  color: #0bd4c4;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  width: 70%;
  padding-left: 40px;
  padding-top: 32px;
}

@media screen and (min-width: 768px) {
  .mobile-menu__user-info .text {
    font-size: 2.2rem;
  }
}

.mobile-menu__settings-image {
  width: 21px;
  height: 21px;
  margin-left: 40px;
  margin-bottom: 50px;
  display: inline-block;
  vertical-align: top;
}

.header__user-logout {
  color: white;
}

.header__user-logout:hover {
  color: white;
}

.header__button {
  border: 1px solid #00d0c1;
  border-radius: 20px;
  background: none;
  box-shadow: none;
  padding: 6px 30px;
  font-size: 1.4rem;
  font-weight: 400;
  color: white;
  transition: all 0.5s ease;
  width: 110px;
  cursor: pointer;
}

.header__button:hover {
  border-color: #0bd4c4;
  color: #4a4a4c;
  background-color: #0bd4c4;
  font-weight: 600;
}

.header__button:focus {
  outline: none;
}

.header__button-mobile {
  margin-left: 40px;
  margin-bottom: 40px;
  cursor: pointer;
}

.header__button-mobile:hover {
  border-color: #0bd4c4;
  color: #4a4a4c;
  background-color: #0bd4c4;
  font-weight: 600;
}

.main-page-top {
  background-color: #4a4a4c;
  padding-bottom: 10px;
}

@media (min-width: 1016px) {
  .main-page-top {
    padding-bottom: 20px;
  }
}

.main-page-top .container {
  position: relative;
}

.main-page-top__header {
  font-weight: 300;
  padding-top: 30px;
  margin-bottom: 34px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1016px) {
  .main-page-top__header {
    padding-top: 80px;
  }
}

.main-page-top__subheader {
  font-size: 1.3rem;
  text-align: center;
  color: #0bd4c4;
  margin-bottom: 35px;
}

.main-page-top__form {
  position: relative;
  width: 80%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.main-page-top__email-input {
  outline: none;
  display: block;
  width: 100%;
  border-radius: 30px;
  height: 60px;
  font-size: 1.2rem;
  box-shadow: none;
  padding-left: 19px;
  border: 2px solid #0bd4c4;
  background-color: transparent;
  color: white;
  font-size: 1.2rem;
}

.main-page-top__email-input::-webkit-input-placeholder {
  color: white;
}

.main-page-top__email-input::-moz-placeholder {
  color: white;
}

.main-page-top__email-input:-ms-input-placeholder {
  color: white;
}

.main-page-top__email-input:-moz-placeholder {
  color: white;
}

@media (min-width: 425px) {
  .main-page-top__email-input {
    padding-left: 39px;
  }
}

.main-page-top__email-input-sent {
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  height: 60px;
  font-size: 1.2rem;
  box-shadow: none;
  color: #4a4a4c;
  font-weight: 600;
  background-color: #0bd4c4;
  border: none;
  transition: all 0.5s ease;
  cursor: pointer;
}

.main-page-top__email-input-sent:hover {
  background-color: transparent;
  border: 2px solid #0bd4c4;
}

@media (min-width: 425px) {
  .main-page-top__email-input-sent:hover {
    font-size: 1.4rem;
  }
}

.main-page-top__select-container {
  position: relative;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.main-page-top__select {
  width: 40%;
  outline: none;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 100%;
  border-radius: 30px;
  height: 60px;
  font-size: 1.2rem;
  box-shadow: none;
  padding-left: 10px;
  padding-right: 45px;
  border: 2px solid #0bd4c4;
  background-color: #4a4a4c;
  color: white;
  font-size: 1.2rem;
  -webkit-appearance: none;
}

.main-page-top__select::-webkit-input-placeholder {
  color: white;
}

.main-page-top__select::-moz-placeholder {
  color: white;
}

.main-page-top__select:-ms-input-placeholder {
  color: white;
}

.main-page-top__select:-moz-placeholder {
  color: white;
}

@media (min-width: 1016px) {
  .main-page-top__select {
    padding-left: 39px;
  }
}

.main-page-top__select .select-text-desktop {
  display: none;
}

@media (min-width: 675px) {
  .main-page-top__select .select-text-desktop {
    display: block;
  }
}

@media (min-width: 675px) {
  .main-page-top__select .select-text-mobile {
    display: none;
  }
}

.main-page-top__select option {
  font-size: 1.2rem;
  width: 150px;
}

.main-page-top__select-btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  height: 60px;
  font-size: 1.2rem;
  box-shadow: none;
  color: #4a4a4c;
  font-weight: 600;
  background-color: #0bd4c4;
  border: none;
  transition: all 0.5s ease;
}

.main-page-top__select-btn:hover {
  background-color: transparent;
  border: 2px solid #0bd4c4;
  font-size: 1.6rem;
}

.main-page-top__img-container {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (min-width: 1016px) {
  .main-page-top__img-container {
    width: 100%;
    height: auto;
    padding-top: 30px;
    padding-bottom: 50px;
  }
}

.main-page-top__img {
  display: block;
  width: 100%;
  height: auto;
  max-width: 435px;
  max-height: auto;
}

@media (min-width: 1016px) {
  .main-page-top__img {
    display: none;
  }
}

.main-page-top__img--bigger {
  display: none;
}

@media (min-width: 1016px) {
  .main-page-top__img--bigger {
    display: block;
    width: 100%;
    height: auto;
    max-width: 511px;
    max-height: auto;
  }
}

.main-page-top__footer-decor {
  transform: rotate(180deg);
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
}

.main-page-top__decor {
  display: none;
}

@media (min-width: 1016px) {
  .main-page-top__decor {
    display: block;
    position: absolute;
    z-index: 10;
  }
}

.main-page-top__decor-1 {
  top: 230px;
  right: -20px;
  width: 180px;
}

.main-page-top__decor-2 {
  top: 285px;
  left: 112px;
  width: 49px;
}

.main-page-top__decor-3 {
  top: 397px;
  left: -80px;
  width: 118px;
}

.main-page-top__decor-4 {
  top: 590px;
  left: -80px;
  width: 89px;
}

.main-page-top__decor-5 {
  top: 500px;
  right: 200px;
  width: 79px;
}

.main-page-top__decor-6 {
  top: 559px;
  right: 30px;
  width: 110px;
}

.main-page-top__decor-7 {
  top: 309px;
  right: 130px;
  width: 59px;
}

.main-page-how-it-works {
  background-color: white;
}

.main-page-how-it-works__flex-container {
  display: flex;
  flex-direction: column;
}

@media (min-width: 1016px) {
  .main-page-how-it-works__flex-container {
    flex-direction: row;
    justify-content: space-between;
  }
}

.main-page-how-it-works__text-container {
  text-align: center;
}

@media (min-width: 1016px) {
  .main-page-how-it-works__text-container .decor {
    margin-left: 0;
  }
}

@media (min-width: 1016px) {
  .main-page-how-it-works__text-container {
    padding-left: 20px;
    flex: 0.3;
  }
}

.main-page-how-it-works__steps-container {
  padding-bottom: 40px;
}

@media (min-width: 1016px) {
  .main-page-how-it-works__steps-container {
    flex: 0.7;
    display: flex;
    flex-direction: row;
    padding-bottom: 0;
  }
}

.main-page-how-it-works__heading {
  color: #4a4a4c;
  padding-top: 60px;
  padding-bottom: 1px;
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
}

@media (min-width: 1016px) {
  .main-page-how-it-works__heading {
    padding-top: 100px;
    text-align: left;
  }
}

.main-page-how-it-works__decor {
  margin: 19px auto;
}

@media (min-width: 1016px) {
  .main-page-how-it-works__step {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 33%;
    position: relative;
  }
}

.main-page-how-it-works__step-number {
  position: relative;
  color: #0bd4c4;
  font-size: 4.3rem;
  line-height: 69px;
  font-weight: 400;
  width: 67px;
  height: 67px;
  border: 1px solid white;
  box-shadow: 0 0 0 7px rgba(230, 230, 230, 0.4);
  border-radius: 50%;
  text-align: center;
  margin: 20px auto;
}

@media (min-width: 1016px) {
  .main-page-how-it-works__step-number {
    width: 100px;
    height: 100px;
    font-size: 7.3rem;
    line-height: 100px;
  }
}

.main-page-how-it-works__step-number::after {
  background: none;
  border: 1px solid rgba(230, 230, 230, 0.6);
  border-radius: 50%;
  content: "";
  display: block;
  position: absolute;
  top: -12px;
  left: -12px;
  right: -12px;
  bottom: -12px;
  pointer-events: none;
}

.main-page-how-it-works__arrow {
  display: block;
  margin: 0 auto;
  width: 19px;
}

@media (min-width: 1016px) {
  .main-page-how-it-works__arrow {
    width: 22px;
    display: inline;
    position: absolute;
    top: 36%;
    right: -9px;
    transform: rotate(270deg);
  }
}

@media (min-width: 1016px) {
  .main-page-how-it-works__arrow--last {
    display: none;
  }
}

.main-page-how-it-works__subheader {
  color: #0bd4c4;
  font-size: 2rem;
  margin-top: 13px;
  margin-bottom: 13px;
  text-align: center;
  font-weight: 400;
}

@media (min-width: 1016px) {
  .main-page-how-it-works__subheader {
    padding-top: 35px;
    width: 64%;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
  }
}

.main-page-how-it-works .text {
  width: 85%;
  color: #c7c7c7;
  margin-bottom: 10px;
  max-width: 428px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1016px) {
  .main-page-how-it-works .text {
    margin-bottom: 70px;
    text-align: left;
    width: 80%;
    margin-left: 0;
  }
}

.main-page-more-about {
  background-color: #e6e6e6;
  color: #4a4a4c;
}

.main-page-more-about__link {
  display: block;
  text-transform: uppercase;
  color: #4a4a4c;
  font-size: 1.2rem;
  line-height: 2.2rem;
  padding-top: 42px;
  padding-bottom: 42px;
  text-align: center;
}

@media (min-width: 1016px) {
  .main-page-more-about__link {
    font-size: 1.8rem;
  }
}

.main-page-why-us {
  background-color: #0bd4c4;
  background: #00c6c4;
  background: -moz-linear-gradient(left, #00c6c4 0%, #00c6c4 15%, #0bd4c4 50%, #00c6c4 90%, #00c6c4 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, #00c6c4), color-stop(15%, #00c6c4), color-stop(50%, #0bd4c4), color-stop(90%, #00c6c4), color-stop(100%, #00c6c4));
  background: -webkit-linear-gradient(left, #00c6c4 0%, #00c6c4 15%, #0bd4c4 50%, #00c6c4 90%, #00c6c4 100%);
  background: -o-linear-gradient(left, #00c6c4 0%, #00c6c4 15%, #0bd4c4 50%, #00c6c4 90%, #00c6c4 100%);
  background: -ms-linear-gradient(left, #00c6c4 0%, #00c6c4 15%, #0bd4c4 50%, #00c6c4 90%, #00c6c4 100%);
  background: linear-gradient(to right, #00c6c4 0%, #00c6c4 15%, #0bd4c4 50%, #00c6c4 90%, #00c6c4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr= '#00c6c4', endColorstr='#00c6c4', GradientType=1 );
}

@media (min-width: 1016px) {
  .main-page-why-us__mobile {
    display: none;
  }
}

.main-page-why-us .container {
  position: relative;
}

.main-page-why-us__subheader {
  color: white;
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 60px;
  text-align: center;
}

.main-page-why-us__img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 50px;
  margin-bottom: 14px;
}

.main-page-why-us__table {
  width: 100%;
}

.main-page-why-us__table td {
  padding-left: 0;
  padding-right: 0;
  vertical-align: top;
}

.main-page-why-us__table .table-left {
  width: 50%;
  text-align: right;
}

.main-page-why-us__table .table-right {
  width: 50%;
  text-align: left;
}

.main-page-why-us__table .padding-left {
  padding-left: 20px;
}

.main-page-why-us__table .padding-right {
  padding-right: 20px;
}

.main-page-why-us__table .text {
  font-size: 1.2rem;
  line-height: 1.4rem;
  padding-bottom: 19px;
}

.main-page-why-us__sub-subheader {
  font-size: 1.4rem;
  margin-bottom: 1px;
  font-weight: 600;
}

@media (min-width: 1016px) {
  .main-page-why-us__sub-subheader {
    margin-bottom: 4px;
  }
}

.main-page-why-us__decor {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 30px;
  padding-top: 10px;
  padding-bottom: 20px;
}

.main-page-why-us__desktop {
  display: none;
}

@media (min-width: 1016px) {
  .main-page-why-us__desktop {
    display: block;
  }
}

.main-page-why-us__desktop .main-page-why-us__subheader {
  display: inline;
  font-size: 2.8rem;
  margin-top: 24px;
  margin-bottom: 0;
}

.main-page-why-us__desktop-flex-container {
  display: flex;
  flex-direction: row;
}

.main-page-why-us__left-container {
  flex: 0.3;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main-page-why-us__left-container .text, .main-page-why-us__left-container .main-page-why-us__sub-subheader {
  text-align: right;
  padding-right: 40px;
  margin-left: 0;
  margin-right: auto;
}

.main-page-why-us__left-container .main-page-why-us__sub-container img {
  right: -10px;
}

.main-page-why-us__big-logo-container {
  flex: 0.4;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.main-page-why-us__bracket {
  height: 530px;
  width: auto;
  padding-top: 116px;
  padding-bottom: 116px;
}

.main-page-why-us__small-logo-container {
  display: inline-flex;
  flex-direction: column;
}

.main-page-why-us__small-logo-container img {
  width: 124px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.main-page-why-us__right-container {
  flex: 0.3;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main-page-why-us__right-container .text, .main-page-why-us__right-container .main-page-why-us__sub-subheader {
  margin-right: 0;
  margin-left: auto;
  width: 100%;
  padding-left: 40px;
}

.main-page-why-us__right-container img {
  left: -20px;
}

.main-page-why-us__sub-container {
  position: relative;
  margin-top: 40px;
  margin-bottom: 40px;
  height: 70px;
}

.main-page-why-us__sub-container .text {
  width: 100%;
  line-height: 1.7rem;
}

.main-page-why-us__sub-container img {
  display: inline;
  position: absolute;
  top: 0;
  right: 0;
}

.main-page-why-us__left-center {
  padding-right: 30px;
}

.main-page-why-us__left-center img {
  right: -10px;
  padding-right: 15px;
}

.main-page-why-us__left-bottom .left-bottom-img {
  right: -26px !important;
}

.main-page-why-us__right-center {
  padding-left: 30px;
}

.main-page-why-us__right-center img {
  left: 0;
  padding-left: 10px;
}

.main-page-why-us__decor-1 {
  width: 107px;
  left: -100px;
  top: 398px;
}

.main-page-why-us__decor-2 {
  width: 157px;
  right: -60px;
  top: 498px;
}

@media (min-width: 1016px) {
  .main-page-mentors__flex-container {
    display: flex;
    flex-direction: row;
  }
}

@media (min-width: 1016px) {
  .main-page-mentors__text-container {
    order: 2;
    flex: 0.4;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.main-page-mentors__mentor-continer .reservation__mentor-description .reservation__mentor-text {
  font-weight: 300;
}

@media (min-width: 1016px) {
  .main-page-mentors__mentor-continer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    order: 1;
    flex: 0.6;
  }
}

.main-page-mentors__heading {
  color: #4a4a4c;
  padding-top: 60px;
  padding-bottom: 1px;
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
}

@media (min-width: 1016px) {
  .main-page-mentors__heading {
    padding-top: 10px;
    text-align: left;
  }
}

.main-page-mentors .text {
  width: 85%;
  color: #c7c7c7;
  margin-bottom: 30px;
  max-width: 428px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

@media (min-width: 1016px) {
  .main-page-mentors .text {
    text-align: left;
    margin-left: 0;
  }
}

@media (min-width: 1016px) {
  .main-page-mentors .decor {
    margin-left: 0;
  }
}

.main-page-mentors .reservation__mentor-description {
  color: #c7c7c7;
}

.main-page-mentors__mentor-column {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding-bottom: 60px;
}

@media (min-width: 1016px) {
  .main-page-mentors__mentor-column {
    width: 50%;
    padding-bottom: 0;
    height: 290px;
    margin-top: 95px;
    margin-bottom: 30px;
  }
}

.main-page-mentors__mentor-image img {
  width: 130px;
  padding-bottom: 20px;
  filter: grayscale(1);
}

.main-page-about-us {
  background-color: #4a4a4c;
}

.main-page-about-us .container {
  position: relative;
}

@media (min-width: 1016px) {
  .main-page-about-us__flex-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

@media (min-width: 1016px) {
  .main-page-about-us__text-container {
    flex: 0.4;
  }
}

.main-page-about-us__heading {
  color: white;
  padding-top: 60px;
  padding-bottom: 1px;
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
}

@media (min-width: 1016px) {
  .main-page-about-us__heading {
    text-align: left;
  }
}

.main-page-about-us__img-container {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (min-width: 1016px) {
  .main-page-about-us__img-container {
    flex: 0.6;
    margin-right: 0;
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.main-page-about-us__img {
  display: block;
  width: 100%;
  height: auto;
  max-width: 435px;
  max-height: auto;
}

@media (min-width: 1016px) {
  .main-page-about-us__img {
    display: none;
  }
}

.main-page-about-us__img--bigger {
  display: none;
}

@media (min-width: 1016px) {
  .main-page-about-us__img--bigger {
    display: inline;
    width: 85%;
    height: auto;
    max-width: 846px;
  }
}

.main-page-about-us__footer-decor {
  transform: rotate(180deg);
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
}

.main-page-about-us .text {
  width: 85%;
  color: #c7c7c7;
  margin-bottom: 10px;
  max-width: 428px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

@media (min-width: 1016px) {
  .main-page-about-us .text {
    text-align: left;
    margin-left: 0;
  }
}

@media (min-width: 1016px) {
  .main-page-about-us .decor {
    margin-left: 0;
  }
}

.main-page-about-us__decor-1 {
  width: 93px;
  left: -30px;
  top: -50px;
}

.main-page-about-us__decor-2 {
  width: 90px;
  right: 80px;
  top: 409px;
}

@media (min-width: 1016px) {
  .main-page-contact {
    background-image: url("../img/map.png");
  }
}

.main-page-contact__heading {
  color: #4a4a4c;
  padding-top: 60px;
  padding-bottom: 1px;
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
}

@media (min-width: 1016px) {
  .main-page-contact__heading {
    padding-top: 130px;
  }
}

.main-page-contact__link {
  display: block;
  color: #0bd4c4;
  text-decoration: none;
  text-align: center;
  font-size: 1.6rem;
  margin-top: 10px;
  padding-bottom: 30px;
  transition: all 0.5s ease;
}

.main-page-contact__link:hover {
  font-weight: 600;
  font-size: 1.7rem;
}

@media (min-width: 1016px) {
  .main-page-contact__container {
    width: 480px;
    height: 472px;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
  }
}

.main-page-contact .text {
  color: #c7c7c7;
  margin-bottom: 10px;
  text-align: center;
}

.main-page-top__select-container {
  position: relative;
}

.main-page-top__select-container p {
  font-size: 14px;
  line-height: 52px;
  font-weight: 300;
  padding-left: 10px;
}

.main-page-top__select-container ul {
  list-style: none;
  position: absolute;
  top: 52px;
  left: 0px;
  overflow: overlay;
  background: #4a4a4c;
  width: 95%;
  max-height: 220px;
}

.main-page-top__select-container ul::-webkit-scrollbar {
  background-color: #4a4a4c;
}

.main-page-top__select-container ul::-webkit-scrollbar-thumb {
  background-color: #0bd4c4;
}

.main-page-top__select-container ul li {
  font-size: 14px;
  font-weight: 300;
  padding-left: 10px;
  padding: 15px 20px;
  overflow: hidden;
}

.main-page-top__select-container ul li:first-child {
  margin-top: 15px;
}

.main-page-top__select-container ul li:hover {
  background: #0bd4c4;
  cursor: pointer;
}

@media (min-width: 768px) {
  .main-page-top__select-container ul {
    max-height: 180px;
    left: 33px;
    width: 54.5%;
  }
}

.pricing {
  background-color: #4a4a4c;
  padding-bottom: 85px;
  padding-top: 50px;
}

@media (min-width: 1016px) {
  .pricing {
    padding-bottom: 160px;
  }
}

@media (min-width: 1016px) {
  .pricing {
    padding-top: 80px;
  }
}

.pricing__subheader {
  font-size: 3rem;
  font-weight: 300;
  text-align: center;
  margin: 40px auto 60px;
}

@media (min-width: 1016px) {
  .pricing__subheader {
    margin: 80px auto 55px;
  }
}

.pricing__subheader--green {
  margin: 0 auto 0;
}

@media (min-width: 1016px) {
  .pricing__subheader--green {
    margin: 0 auto 0;
  }
}

.pricing__subheader strong {
  font-size: 3rem;
  font-weight: 600;
}

@media (min-width: 1016px) {
  .pricing__flex-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .pricing__flex-container .pricing__flex-sub-container {
    width: 38%;
  }
  .pricing__flex-container .pricing__accented {
    margin-top: 0;
  }
}

.pricing__bracket {
  display: none;
}

@media (min-width: 1016px) {
  .pricing__bracket {
    display: block;
  }
}

.pricing__accented {
  font-size: 1.7rem;
  text-align: center;
  margin: 50px auto 0 auto;
}

.pricing__accented--green {
  color: #0bd4c4;
}

@media (min-width: 1016px) {
  .pricing__accented--green {
    margin: 22px auto 0 auto;
  }
}

.pricing__accented--no-margin {
  margin: 0 auto;
}

@media (min-width: 1016px) {
  .pricing__paragraph-flex-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.pricing__list-paragraph {
  margin-top: 55px;
}

@media (min-width: 1016px) {
  .pricing__list-paragraph {
    border-right: 3px solid #0bd4c4;
    padding-right: 18px;
    width: 50%;
  }
}

.pricing__list-paragraph .text {
  text-align: center;
  max-width: 330px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1016px) {
  .pricing__list-paragraph .text {
    text-align: right;
    max-width: none;
  }
}

.pricing__list-paragraph h2 {
  text-align: center;
}

@media (min-width: 1016px) {
  .pricing__list-paragraph h2 {
    text-align: right;
  }
}

@media (min-width: 1016px) {
  .pricing__list-paragraph--last {
    border-right: none;
    padding-left: 13px;
    width: 50%;
  }
  .pricing__list-paragraph--last .text, .pricing__list-paragraph--last h2 {
    text-align: left;
  }
}

.pricing__list {
  margin-top: 15px;
  list-style: none;
}

.reservations {
  background-color: #4a4a4c;
}

.reservations__header {
  padding-top: 50px;
}

@media (min-width: 1016px) {
  .reservations__header {
    padding-top: 80px;
  }
}

.reservations__username {
  font-size: 2rem;
  text-align: center;
  margin: 30px auto 60px;
}

@media (min-width: 1016px) {
  .reservations__username {
    margin: 30px auto 82px;
  }
}

.reservations__details-section {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  max-width: 550px;
  padding-bottom: 85px;
}

@media (min-width: 1016px) {
  .reservations__details-section {
    width: 80%;
    max-width: none;
    padding-bottom: 130px;
  }
}

.reservations .accent-hr {
  width: 100%;
}

@media (min-width: 1016px) {
  .reservations .accent-hr {
    width: 80%;
  }
}

.reservations__table {
  table-layout: auto;
  width: 100%;
}

.reservations__table .darker {
  color: #8b8b8b;
  font-size: 1.2rem;
}

.reservations__table .accent {
  color: #0bd4c4;
  font-size: 1.2rem;
}

.reservations__table .table-date {
  width: 18%;
}

.reservations__table .table-teacher {
  width: 25%;
}

.reservations__table .table-price {
  width: 12%;
}

.reservations__table .table-button {
  width: 15%;
}

.reservations__table .edit-button {
  width: 20px;
  height: 20px;
  font-size: 1.1rem;
  line-height: 1.3rem;
}

.reservations__table td {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 300;
  text-align: left;
  margin-bottom: 10px;
  width: 15%;
  vertical-align: top;
}

.reservations__table--unactive {
  color: #8b8b8b;
}

@media (min-width: 1016px) {
  .reservations__table {
    display: none;
  }
}

.reservations__table-desktop {
  display: none;
}

@media (min-width: 1016px) {
  .reservations__table-desktop {
    display: block;
  }
  .reservations__table-desktop--unactive {
    color: #8b8b8b;
  }
  .reservations__table-desktop .table-teacher {
    width: 15%;
  }
  .reservations__table-desktop .table-button {
    width: 10%;
  }
  .reservations__table-desktop td {
    font-size: 1.5rem;
    line-height: 3rem;
    font-weight: 300;
    text-align: left;
    vertical-align: top;
    margin-bottom: 10px;
    width: 8%;
  }
}

.reservations__edit-button {
  width: 70%;
  text-align: center;
}

.reservations__confirm {
  width: 100%;
  display: none;
}

.reservations__confirm-form {
  padding-top: 25px;
  padding-bottom: 25px;
}

.reservations__confirm-checkobox {
  width: 18px;
  height: 18px;
}

@media (min-width: 1016px) {
  .reservations__confirm-checkobox {
    width: 13px;
    height: 13px;
  }
}

.reservations__confirm-submit {
  width: 70%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.reservations__confirm-submit--mobile {
  width: 100%;
  max-width: 150px;
}

.reservations__accented {
  font-size: 2.4rem;
  text-align: center;
  margin: 30px auto 0;
  padding-bottom: 300px;
}

.mentor-panel {
  background-color: #4a4a4c;
  width: 100%;
}

.mentor-panel__header {
  padding-top: 50px;
}

@media (min-width: 1016px) {
  .mentor-panel__header {
    padding-top: 80px;
  }
}

.mentor-panel__list {
  width: 100%;
  list-style: none;
  text-align: center;
}

.mentor-panel__list-element {
  margin-bottom: 40px;
  text-align: center;
}

@media (min-width: 1016px) {
  .mentor-panel__list-element {
    margin-bottom: 20px;
  }
}

.mentor-panel__list-element .text {
  font-size: 1.6rem;
}

.mentor-panel__list-element .button {
  width: 120px;
  height: 30px;
  margin-top: 15px;
  margin-bottom: 25px;
}

.mentor-panel__info {
  margin-bottom: 0;
}

@media (min-width: 1016px) {
  .mentor-panel__info {
    display: inline;
    margin-right: 10px;
  }
}

.mentor-panel__info-full {
  display: block;
  text-align: left;
  margin-top: 15px;
  margin-left: 10%;
}

.mentor-panel .container {
  padding-bottom: 60px;
}

.mentor-panel .pricing__subheader {
  font-weight: 600;
  color: white;
}

.mentor-panel .text--accented {
  font-size: 1.6rem;
  font-weight: 600;
  width: 40%;
}

@media (min-width: 1016px) {
  .mentor-panel .text--accented {
    margin-left: 10px;
  }
}

.mentor-panel .text--no-margin {
  margin-left: 0;
}

.mentor-panel .text--grey {
  color: #8b8b8b;
}

.settings {
  background-color: #4a4a4c;
  padding-bottom: 85px;
  padding-top: 50px;
}

@media (min-width: 1016px) {
  .settings {
    padding-bottom: 90px;
  }
}

@media (min-width: 1016px) {
  .settings {
    padding-top: 80px;
  }
}

.settings__table {
  width: 100%;
  margin-bottom: 60px;
}

.settings__table .left {
  text-align: right;
  padding-right: 10px;
  width: 50%;
}

.settings__table .right {
  padding-left: 10px;
  width: 50%;
}

.settings__table .darker {
  color: #8b8b8b;
}

.settings__table .accent {
  color: #0bd4c4;
  font-size: 1.2rem;
}

.settings__table .padding-top {
  padding-top: 40px;
}

.settings__table .accent-hr {
  margin: 9px 0 0;
  width: 35%;
  max-width: 100px;
}

.settings__table td {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 300;
  text-align: left;
  margin-bottom: 10px;
  vertical-align: top;
}

.settings__table--unactive {
  color: #8b8b8b;
}

.settings__buttons-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 1016px) {
  .settings__buttons-section {
    flex-direction: row;
    justify-content: center;
    margin-bottom: 50px;
  }
}

.settings__buttons-section .button {
  margin-bottom: 20px;
  width: 150px;
  height: 43px;
  border-radius: 20px;
}

@media (min-width: 1016px) {
  .settings__buttons-section .button {
    width: 84px;
    height: 24px;
    font-size: 1.2rem;
  }
}

.settings__buttons-section .button:nth-child(2) {
  width: 220px;
}

@media (min-width: 1016px) {
  .settings__buttons-section .button:nth-child(2) {
    width: 123px;
    margin-left: 45px;
    margin-right: 45px;
  }
}

.settings__desktop-buttons-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.settings__desktop-buttons-section .button {
  margin-top: 15px;
  width: 123px;
  background-color: #0bd4c4;
  border-color: #0bd4c4;
  font-weight: 600;
  color: #4a4a4c;
}

.settings__desktop-buttons-section .button:hover {
  border-color: #0bd4c4;
  color: white;
  background-color: transparent;
  font-weight: 400;
}

.settings__desktop-buttons-section .settings__change-password {
  border: 1px solid #0bd4c4;
  background: none;
  box-shadow: none;
  font-weight: 300;
  color: white;
  width: 150px;
  margin-top: 40px;
}

.settings__desktop-buttons-section .settings__change-password:hover {
  color: #4a4a4c;
  background-color: #0bd4c4;
  font-weight: 600;
}

.settings__change-password-form {
  display: none;
}

.settings__change-password-form label {
  display: block;
  margin-top: 20px;
  text-align: center;
}

.settings__change-password-input {
  background: #333333;
  border: none;
  width: 100%;
  display: block;
  margin: 10px auto 10px;
  font-size: 1.4rem;
  border-radius: 10px;
  color: white;
  padding: 5px 10px;
}

.settings__change-password-input-sent {
  display: block;
  margin: 0 auto;
}

.regulations {
  background-color: #4a4a4c;
  padding-bottom: 85px;
  padding-top: 50px;
}

@media (min-width: 1016px) {
  .regulations {
    padding-bottom: 90px;
  }
}

@media (min-width: 1016px) {
  .regulations {
    padding-top: 80px;
  }
}

.regulations__subheading {
  display: block;
  font-size: 2rem;
  text-align: center;
  line-height: 3rem;
  text-transform: uppercase;
  font-weight: 600;
  padding-top: 20px;
  padding-bottom: 20px;
}

.regulations__main-list {
  list-style: none;
}

.regulations__list {
  padding-left: 20px;
  margin-bottom: 20px;
}

.regulations__list-element {
  padding-bottom: 15px;
  font-size: 1.4rem;
  line-height: 2.5rem;
}

.regulations__list-element strong {
  padding-bottom: 15px;
  font-size: 1.4rem;
  line-height: 2.5rem;
}

.regulations .text {
  font-size: 1.4rem;
}

.footer {
  color: #4a4a4c;
  position: relative;
  border-top: 1px solid #0bd4c4;
}

.footer__footer-decor {
  position: relative;
  top: 15px;
  transform: rotate(180deg);
  display: block;
  width: 16px;
  margin-left: auto;
  margin-right: auto;
}

.footer__container {
  max-width: 270px;
  position: relative;
  margin-top: 20px;
  padding-left: 16px;
  padding-right: 9px;
}

@media (min-width: 1016px) {
  .footer__container {
    max-width: 1020px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 140px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.footer__links-section {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
}

@media (min-width: 1016px) {
  .footer__links-section {
    padding-top: 0;
    flex: 0.3;
  }
  .footer__links-section .link {
    margin-right: 50px;
  }
}

.footer__img-container {
  width: 36px;
  margin: 10px auto;
  position: absolute;
  top: 10px;
  left: 117px;
}

@media (min-width: 1016px) {
  .footer__img-container {
    position: relative;
    top: 0;
    left: 0;
    margin: 0;
  }
}

.footer .legal {
  text-align: center;
  position: absolute;
  top: 65px;
  left: 70px;
  padding-bottom: 35px;
}

@media (min-width: 1016px) {
  .footer .legal {
    position: relative;
    top: 0;
    left: 0;
    flex: 0.3;
    text-align: right;
    padding-bottom: 0;
  }
}

.reservation {
  background: #4a4a4c;
}

.reservation__mentor-column {
  display: flex;
  height: auto;
  margin-top: 25px;
  margin-bottom: 60px;
}

.reservation__container {
  padding: 60px 0;
}

.reservation__container .col-md-4 {
  padding-bottom: 60px;
}

.reservation__container .justify-content-md-center {
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.reservation__header {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  padding: 40px 0;
  position: relative;
}

.reservation__header:after {
  content: " ";
  display: block;
  width: 40px;
  height: 2px;
  bottom: 15px;
  background: #0BD4C4;
  position: absolute;
  left: calc(50% - 25px);
}

.reservation__rating {
  margin-bottom: 15px;
}

.reservation__mentor-description {
  width: 50%;
  height: 160px;
}

.reservation__mentor-description h2 {
  font-size: 20px;
  font-weight: 600;
  color: #00d0c1;
  margin-bottom: 15px;
}

.reservation__mentor-description p {
  font-size: 12px;
  margin-bottom: 10px;
}

.reservation__mentor-description .reservation__mentor-text {
  margin-bottom: 0;
  line-height: 1.2;
  font-weight: 600;
}

.reservation__mentor-description .reservation__button-container {
  margin-top: 10px;
  color: #c7c7c7;
}

.reservation__mentor-description .reservation__button-container p {
  margin: 0;
  font-size: 11px;
  font-weight: 600;
}

.reservation__mentor-description .active {
  color: #0BD4C4;
  line-height: 1.4;
}

.reservation__mentor-description button {
  margin-top: 10px;
  border: 1px solid #0BD4C4;
  border-radius: 20px;
  background: none;
  box-shadow: none;
  padding: 3px 30px;
  font-size: 12px;
  font-weight: 600;
  color: white;
}

.reservation__mentor-image {
  width: 50%;
  align-self: center;
  border-radius: 100%;
  margin-right: 20px;
  overflow: hidden;
}

.reservation__mentor-image img {
  width: 100%;
  max-width: 140px;
}

.reservation__filter {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reservation__filter p {
  top: 40px;
  right: 40px;
  font-size: 14px;
  color: #999999;
  font-weight: 600;
  width: 298px;
  text-align: center;
}

@media (min-width: 1016px) {
  .reservation__filter p {
    position: absolute;
    text-align: right;
    width: 21%;
  }
}

.reservation__filter strong {
  font-size: 12px;
  color: #FFF;
  font-weight: 600;
  padding-left: 20px;
  display: inline-block;
  position: relative;
}

.reservation__filter strong:after {
  content: "";
  display: block;
  position: absolute;
  right: -20px;
  top: 7px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 7px solid #0BD4C4;
}

.reservation__dropdown {
  right: 20px;
  top: 70px;
  background: #4a4a4c;
  list-style: none;
  z-index: 1;
  overflow: overlay;
  max-height: 150px;
  width: 290px;
}

.reservation__dropdown::-webkit-scrollbar {
  background-color: #4a4a4c;
}

.reservation__dropdown::-webkit-scrollbar-thumb {
  background-color: #0bd4c4;
}

.reservation__dropdown li {
  font-size: 12px;
  padding: 10px 20px;
}

.reservation__dropdown li:last-child {
  margin: 0;
}

.reservation__dropdown li:hover {
  background: #0BD4C4;
  color: #2d2d2d;
  cursor: pointer;
}

@media (min-width: 1016px) {
  .reservation__dropdown {
    position: absolute;
    width: 200px;
  }
}

.reservation-modal {
  width: 90%;
  margin: 15px auto;
  max-width: 1080px;
}

@media (min-width: 1016px) {
  .reservation-modal {
    margin: 7% auto;
  }
}

.openModal {
  margin-bottom: 25px;
}

.reservation-modal__content {
  background: #2d2d2d;
}

.reservation-modal__content .modal-header {
  border-bottom: 0;
}

.reservation-modal__content .modal-header img {
  width: 25px;
  height: 25px;
  margin: 10px;
  cursor: pointer;
}

.reservation-modal__content p {
  font-size: 14px;
}

.reservation-modal__image {
  width: 100%;
  border-radius: 100%;
  max-width: 160px;
  margin-right: 20px;
}

.reservation-modal__info {
  display: flex;
}

.reservation-modal__rating {
  text-align: center;
  margin-right: 20px;
  margin-top: 25px;
}

.reservation-modal__rating img {
  width: 15px;
}

.reservation-modal__mentor-name {
  font-size: 24px;
  position: relative;
}

.reservation-modal__mentor-name:after {
  content: "";
  display: block;
  margin: 35px 0 15px;
  width: 40px;
  height: 3px;
  background: #0BD4C4;
}

.reservation-modal__description {
  padding-left: 20px;
}

.reservation-modal__description p {
  margin: 0;
  margin-top: 5px;
  font-size: 14px;
  line-height: 1.4;
}

.reservation-modal__description .reservation-modal__price-info {
  font-size: 18px;
  margin-top: 30px;
  color: #00d0c1;
  font-weight: 600;
}

.reservation__modal-summary-row {
  margin-top: 40px;
  padding-bottom: 50px;
}

.reservation__modal-textarea {
  background: black;
  margin-left: 20px;
  margin-bottom: 20px;
  color: white;
  font-size: 12px;
  padding-top: 5px;
  padding-left: 8px;
  width: 90%;
  height: 100%;
  resize: none;
}

.reservation-modal__checkbox-container {
  font-size: 16px;
  margin-left: 20px;
}

.reservation-modal__checkbox-container label {
  font-size: 12px;
  color: white;
  padding-left: 10px;
}

.reservation-modal__checkbox-container a {
  font-size: 12px;
  color: #cccccc;
}

.reservation-modal__summary-header {
  font-size: 16px;
  font-weight: 600;
  color: #00d0c1;
  margin-top: 30px;
  margin-bottom: 30px;
}

.reservation-modal__summary-text {
  font-size: 14px;
  margin: 0;
  color: #aaaaaa;
}

.reservation-modal__summary-text strong {
  font-size: 100%;
  color: #fff;
}

.reservation-modal__summary-price {
  margin-top: 30px;
  color: #00d0c1;
  font-weight: 600;
}

.reservation-modal__sumarry-button-col {
  align-self: flex-end;
}

.reservation-modal__summary-button {
  margin-top: 10px;
  border: 1px solid #00d0c1;
  border-radius: 20px;
  background: none;
  box-shadow: none;
  padding: 6px 30px;
  font-size: 16px;
  font-weight: 600;
  color: white;
  margin-bottom: 60px;
  margin-top: 20px;
}

.reservation-modal__summary-button:focus {
  outline: none;
}

.reservation-modal__title {
  font-size: 24px;
  text-align: center;
  color: #07d2c2;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 30px;
}

@media (min-width: 1016px) {
  .reservation-modal__title {
    margin-top: 0;
  }
}

.reservation-modal__select {
  text-align: center;
}

.reservation-modal__select ul {
  list-style: none;
  max-height: 160px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  display: relative;
}

.reservation-modal__select ul:after {
  display: none;
}

@media (min-width: 1016px) {
  .reservation-modal__select ul:after {
    content: "";
    display: inline-block;
    width: 3px;
    height: 140px;
    position: absolute;
    background: #07d2c2;
    top: 46px;
    left: 50%;
  }
}

.reservation-modal__select li {
  font-size: 14px;
  display: block;
  font-weight: 600;
  margin-bottom: 10px;
  width: 50%;
  padding: 3px 0;
}

.reservation-modal__select li:hover {
  background: #07d2c2;
  color: #2d2d2d;
  cursor: pointer;
}

.reservation-modal__controls {
  position: relative;
}

.reservation-modal__arrows {
  position: absolute;
  right: 0;
  top: 5px;
}

.reservation-modal__arrows img {
  padding-right: 15px;
  cursor: pointer;
}

.reservation-modal__option-active {
  background: #07d2c2;
  color: #2d2d2d;
}

.reservation-modal__form {
  height: 100%;
}

.registration__container {
  background: #4a4a4c;
  font-size: 1.4rem;
  padding: 0 0 80px;
}

.registration__container label {
  font-size: 1.4rem;
  width: 60%;
  display: block;
  margin: auto;
}

.registration__container .help-inline {
  font-size: 1.4rem;
  text-align: center;
  color: #EC644B;
  display: block;
}

.registration__form-wrapper {
  max-width: 600px;
  margin: auto;
  padding-top: 50px;
}

.registration__header {
  padding-top: 40px;
}

@media (min-width: 1016px) {
  .registration__header {
    padding-top: 80px;
  }
}

.register__input {
  background: #333333;
  border: none;
  width: 60%;
  display: block;
  margin: 10px auto 20px;
  font-size: 1.4rem;
  border-radius: 10px;
  color: white;
  padding: 5px 10px;
}

.register__buttons-container {
  text-align: center;
  margin: 20px 0;
}

.register__back-button {
  font-size: 1.4rem;
  color: white;
  margin-right: 40px;
}

.register__success-button {
  border: 1px solid #00d0c1;
  border-radius: 20px;
  background: none;
  box-shadow: none;
  padding: 6px 30px;
  font-size: 1.4rem;
  font-weight: 400;
  color: white;
  transition: all 0.5s ease;
  width: 130px;
  cursor: pointer;
}

.register__success-button:hover {
  border-color: #0bd4c4;
  color: #4a4a4c;
  background-color: #0bd4c4;
  font-weight: 600;
}

.register__success-button:focus {
  outline: none;
}

.registration__control-container {
  border: 1px solid #0BD4C4;
  width: 90%;
  max-width: 300px;
  margin: auto;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  margin-bottom: 40px;
}

.registration__control-container button {
  background: none;
  box-shadow: none;
  border: 0;
  padding: 3px 30px;
  font-size: 14px;
  width: 50%;
  font-weight: 600;
  color: white;
  cursor: pointer;
  transition: all 0.5s ease;
}

.registration__control-container button:hover {
  border-color: #0bd4c4;
  background-color: #0bd4c4;
  font-weight: 600;
}

.registration__control-container #person {
  border-right: 1px solid #0BD4C4;
}

.registration__control-container .active {
  background: #0BD4C4;
}

.registration__checkbox-container {
  width: 60%;
  margin: auto;
}

.registration__checkbox-container input,
.registration__checkbox-container label {
  display: inline-block;
  width: auto;
}

.registration__checkbox-container label {
  padding-left: 20px;
}

.registration__checkbox-container {
  font-size: 1.4rem;
  display: flex;
  width: 60%;
  margin: 30px auto;
}

.registration__checkbox-container label {
  font-size: 1.4rem;
  color: white;
  padding-left: 10px;
  margin: 0;
}

.registration__checkbox-container a {
  font-size: 1.4rem;
}

.login-checkbox input {
  margin: 3px 0;
  border: 1px solid #0bd4c4;
  width: 15px;
  height: 15px;
}

.login-checkbox input:active {
  background-color: #4a4a4c;
}

.header__user-info a {
  display: inline-block;
  color: #8b8b8b;
}

#forgotten-password {
  width: 160px;
  display: block;
  margin: 40px auto;
}

#forgotten-password-email {
  width: 90%;
  max-width: 360px;
}

#forgotten-password-submit {
  width: 140px;
  display: block;
  margin: 20px auto;
}

.custom-style {
  color: #333;
}
