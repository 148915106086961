$blue: #00d0c1;

.reservation-modal {
  width: 90%;
  margin: 15px auto;
  max-width: 1080px;
  @media #{$desktop} {
    margin: 7% auto;
  }
}

.openModal {
  margin-bottom: 25px;
}


.reservation-modal__content {
  background: #2d2d2d;

  .modal-header {
    border-bottom: 0;
    img {
      width: 25px;
      height: 25px;
      margin: 10px;
      cursor: pointer;
    }
  }

  p {
    font-size: 14px;
  }
}

.reservation-modal__image {
  width: 100%;
  border-radius: 100%;
  max-width: 160px;
  margin-right: 20px;
}

.reservation-modal__info {
  display: flex;
}

.reservation-modal__rating {
  text-align: center;
  margin-right: 20px;
  margin-top: 25px;

  img {
    width: 15px;
  }
}

.reservation-modal__mentor-name {
  font-size: 24px;
  position: relative;

  &:after {
    content: "";
    display: block;
    margin: 35px 0 15px;
    width: 40px;
    height: 3px;
    background: #0BD4C4;
  }
}


.reservation-modal__description {
  padding-left: 20px;

  p {
    margin: 0;
    margin-top: 5px;
    font-size: 14px;
    line-height: 1.4;
  }
  .reservation-modal__price-info {
    font-size: 18px;
    margin-top: 30px;
    color: #00d0c1;
    font-weight: 600;
  }
}

.reservation__modal-summary-row {
  margin-top: 40px;
  padding-bottom: 50px;
}

.reservation__modal-textarea {
  background: black;
  margin-left: 20px;
  margin-bottom: 20px;
  color: white;
  font-size: 12px;
  padding-top: 5px;
  padding-left: 8px;
  width: 90%;
  height: 100%;
  resize: none;
}


.reservation-modal__checkbox-container {
  font-size: 16px;
  margin-left: 20px;

  label {
    font-size: 12px;
    color: white;
    padding-left: 10px;
  }

  a {
    font-size: 12px;
    color: darken(#FFF, 20%);
  }
}


.reservation-modal__summary-header {
  font-size: 16px;
  font-weight: 600;
  color: $blue;
  margin-top: 30px;
  margin-bottom: 30px;
}


.reservation-modal__summary-text {
  font-size: 14px;
  margin: 0;
  color: #aaaaaa;

  strong {
    font-size: 100%;
    color: #fff;
  }
}


.reservation-modal__summary-price {
  margin-top: 30px;
  color: $blue;
  font-weight: 600;
}


.reservation-modal__sumarry-button-col {
  align-self: flex-end;
}

.reservation-modal__summary-button {
  margin-top: 10px;
  border: 1px solid $blue;
  border-radius: 20px;
  background: none;
  box-shadow: none;
  padding: 6px 30px;
  font-size: 16px;
  font-weight: 600;
  color: white;
  margin-bottom: 60px;
  margin-top: 20px;
  &:focus {
    outline: none;
  }
}


.reservation-modal__title {
  font-size: 24px;
  text-align: center;
  color: #07d2c2;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 30px;
  @media #{$desktop} {
    margin-top: 0;
  }
}

.reservation-modal__select {
  text-align: center;

  ul {
    list-style: none;
    max-height: 160px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    display: relative;

    &:after {
      display: none;
      @media #{$desktop} {
        content: "";
        display: inline-block;
        width: 3px;
        height: 140px;
        position: absolute;
        background: #07d2c2;
        top: 46px;
        left: 50%;
      }
    }
  }
  li {
    font-size: 14px;
    display: block;
    font-weight: 600;
    margin-bottom: 10px;
    width: 50%;
    padding: 3px 0;

    &:hover {
      background: #07d2c2;
      color: #2d2d2d;
      cursor: pointer;
    }
  }


}

.reservation-modal__controls  {
  position: relative;
}

.reservation-modal__arrows {
  position: absolute;
  right: 0;
  top: 5px;

  img {
    padding-right: 15px;
    cursor: pointer;
  }
}


.reservation-modal__option-active {
  background: #07d2c2;
  color: #2d2d2d;
}


.reservation-modal__form {
  height: 100%;
}
