.footer {
  color: $grey;
  position: relative;
  border-top: 1px solid $green;

  &__footer-decor {
    position: relative;
    top: 15px;
    transform: rotate(180deg);
    display: block;
    width: 16px;
    margin-left: auto;
    margin-right: auto;
  }

  &__container {
    max-width: 270px;
    position: relative;
    margin-top: 20px;
    padding-left: 16px;
    padding-right: 9px;
    @media #{$desktop} {
      max-width: 1020px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 140px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &__links-section {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    @media #{$desktop} {
      padding-top: 0;
      flex: 0.3;
      .link {
        margin-right: 50px;
      }
    }
  }

  &__img-container {
    width: 36px;
    margin: 10px auto;
    position: absolute;
    top: 10px;
    left: 117px;
    @media #{$desktop} {
      position: relative;
      top: 0;
      left: 0;
      margin: 0;
    }
  }
  .legal {
    text-align: center;
    position: absolute;
    top: 65px;
    left: 70px;
    padding-bottom: 35px;
    @media #{$desktop} {
      position: relative;
      top: 0;
      left: 0;
      flex: 0.3;
      text-align: right;
      padding-bottom: 0;
    }
  }
}
