.pricing {
  background-color: $grey;
  padding-bottom: 85px;
  @media #{$desktop} {
    padding-bottom: 160px;
  }
  padding-top: 50px;
  @media #{$desktop} {
    padding-top: 80px;
  }
  &__subheader {
    font-size: 3rem;
    font-weight: 300;
    text-align: center;
    margin: 40px auto 60px;
    @media #{$desktop} {
      margin: 80px auto 55px;
    }
    &--green {
      margin: 0 auto 0;
      @media #{$desktop} {
        margin: 0 auto 0;
      }
    }
    strong {
      font-size: 3rem;
      font-weight: 600;
    }
  }
  &__flex-container {
    @media #{$desktop} {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .pricing__flex-sub-container {
        width: 38%;
      }
      .pricing__accented {
        margin-top: 0;
      }
    }
  }
  &__bracket {
    display: none;
    @media #{$desktop} {
      display: block;
    }
  }
  &__accented {
    font-size: 1.7rem;
    text-align: center;
    margin: 50px auto 0 auto;
    &--green {
      color: $green;
      @media #{$desktop} {
        margin: 22px auto 0 auto;
      }
    }
    &--no-margin {
      margin: 0 auto;
    }
  }
  &__paragraph-flex-container {
    @media #{$desktop} {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
  &__list-paragraph {
    margin-top: 55px;
    @media #{$desktop} {
      border-right: 3px solid $green;
      padding-right: 18px;
      width: 50%;
    }
    .text {
      text-align: center;
      max-width: 330px;
      margin-left: auto;
      margin-right: auto;
      @media #{$desktop} {
        text-align: right;
        max-width: none;
      }
    }
    h2 {
      text-align: center;
      @media #{$desktop} {
        text-align: right;
      }
    }
    &--last {
      @media #{$desktop} {
        border-right: none;
        padding-left: 13px;
        width: 50%;
        .text, h2 {
          text-align: left;
        }
      }
    }
  }
  &__list {
    margin-top: 15px;
    list-style: none;
  }
}
