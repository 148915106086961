.reservations {
  background-color: $grey;

  &__header {
    padding-top: 50px;
    @media #{$desktop} {
      padding-top: 80px;
    }
  }

  &__username {
    font-size: 2rem;
    text-align: center;
    margin: 30px auto 60px;
    @media #{$desktop} {
      margin: 30px auto 82px;
    }
  }

  &__details-section {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    max-width: 550px;
    padding-bottom: 85px;
    @media #{$desktop} {
      width: 80%;
      max-width: none;
      padding-bottom: 130px;
    }
  }

  .accent-hr {
    width: 100%;
    @media #{$desktop} {
      width: 80%;
    }
  }

  &__table {
    table-layout: auto;
    width: 100%;

    .darker {
      color: $taupeGrey;
      font-size: 1.2rem;
    }

    .accent {
      color: $green;
      font-size: 1.2rem;
    }

    .table-date {
      width: 18%;
    }

    .table-teacher {
      width: 25%;
    }

    .table-price {
      width: 12%;
    }

    .table-button {
      width: 15%;
    }

    .edit-button {
      width: 20px;
      height: 20px;
      font-size: 1.1rem;
      line-height: 1.3rem;
    }

    td {
      font-size: 1.5rem;
      line-height: 2rem;
      font-weight: 300;
      text-align: left;
      margin-bottom: 10px;
      width: 15%;
      vertical-align: top;
    }

    &--unactive {
      color: $taupeGrey;
    }
    @media #{$desktop} {
      display: none;
    }
  }

  &__table-desktop {
    display: none;
    @media #{$desktop} {
      display: block;

      &--unactive {
        color: $taupeGrey;
      }

      .table-teacher {
        width: 15%;
      }

      .table-button {
        width: 10%;
      }

      td {
        font-size: 1.5rem;
        line-height: 3rem;
        font-weight: 300;
        text-align: left;
        vertical-align: top;
        margin-bottom: 10px;
        width: 8%;
      }
    }
  }

  &__edit-button {
    width: 70%;
    text-align: center;
  }

  &__confirm {
    width: 100%;
    display: none;
  }

  &__confirm-form {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  &__confirm-checkobox {
    width: 18px;
    height: 18px;
    @media #{$desktop} {
      width: 13px;
      height: 13px;
    }
  }

  &__confirm-submit {
    width: 70%;
    margin-top: 10px;
    margin-bottom: 10px;

    &--mobile {
      width: 100%;
      max-width: 150px;
    }
  }

  &__accented {
    font-size: 2.4rem;
    text-align: center;
    margin: 30px auto 0;
    padding-bottom: 300px;
  }
}
// Mentor panel
.mentor-panel {
  background-color: $grey;
  width: 100%;

  &__header {
    padding-top: 50px;
    @media #{$desktop} {
      padding-top: 80px;
    }
  }

  &__list {
    width: 100%;
    list-style: none;
    text-align: center;
  }
  &__list-element {
    margin-bottom: 40px;
    text-align: center;
    @media #{$desktop} {
      margin-bottom: 20px;
    }
    .text {
      font-size: 1.6rem;
    }
    .button {
      width: 120px;
      height: 30px;
      margin-top: 15px;
      margin-bottom: 25px;
    }
  }

  &__info {
    margin-bottom: 0;
    @media #{$desktop} {
      display: inline;
      margin-right: 10px;
    }
  }
  &__info-full {
    display: block;
    text-align: left;
    margin-top: 15px;
    margin-left: 10%;
  }
  .container {
    padding-bottom: 60px;
  }

  .pricing__subheader {
    font-weight: 600;
    color: $mainColor;
  }

  .text--accented {
    font-size: 1.6rem;
    font-weight: 600;
    width: 40%;
    @media #{$desktop} {
      margin-left: 10px;
    }
  }
  .text--no-margin {
    margin-left: 0;
  }
  .text--grey {
    color: $taupeGrey;
  }
}
