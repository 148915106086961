.settings {
  background-color: $grey;
  padding-bottom: 85px;
  @media #{$desktop} {
    padding-bottom: 90px;
  }
  padding-top: 50px;
  @media #{$desktop} {
    padding-top: 80px;
  }

  &__table {
    width: 100%;
    margin-bottom: 60px;

    .left {
      text-align: right;
      padding-right: 10px;
      width: 50%;
    }

    .right {
      padding-left: 10px;
      width: 50%;
    }

    .darker {
      color: $taupeGrey;
    }

    .accent {
      color: $green;
      font-size: 1.2rem;
    }

    .padding-top {
      padding-top: 40px;
    }

    .accent-hr {
      margin: 9px 0 0;
      width: 35%;
      max-width: 100px;
    }

    td {
      font-size: 1.5rem;
      line-height: 2rem;
      font-weight: 300;
      text-align: left;
      margin-bottom: 10px;
      vertical-align: top;
    }

    &--unactive {
      color: $taupeGrey;
    }
  }

  &__buttons-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media #{$desktop} {
      flex-direction: row;
      justify-content: center;
      margin-bottom: 50px;
    }

    .button {
      margin-bottom: 20px;
      width: 150px;
      height: 43px;
      border-radius: 20px;
      @media #{$desktop} {
        width: 84px;
        height: 24px;
        font-size: 1.2rem;
      }

      &:nth-child(2) {
        width: 220px;
        @media #{$desktop} {
          width: 123px;
          margin-left: 45px;
          margin-right: 45px;
        }
      }
    }
  }

  &__desktop-buttons-section {
    display: flex;
    flex-direction: column;
    align-items: center;

    .button {
      margin-top: 15px;
      width: 123px;
      background-color: $green;
      border-color: $green;
      font-weight: 600;
      color: $grey;

      &:hover {
        border-color: $green;
        color: $mainColor;
        background-color: transparent;
        font-weight: 400;
      }
      // Use when other buttons will be working
      //   margin-bottom: 7px;
      //   width: 240px;
      //   height: 24px;
      //   font-size: 1.2rem;
      //   border-color: $mainColor;
      //   color: $green;
      //   &:hover {
      //     border-color: $green;
      //     color: $grey;
      //     font-weight: 600;
      //   }
      //
      // &:last-child {
      //   @media #{$desktop} {
      //     margin-top: 15px;
      //     width: 123px;
      //     background-color: $green;
      //     border-color: $green;
      //     font-weight: 600;
      //     color: $grey;
      //     &:hover {
      //       border-color: $green;
      //       color: $mainColor;
      //       background-color: transparent;
      //       font-weight: 400;
      //     }
      //   }
      // }
    }

    .settings__change-password {
      border: 1px solid $green;
      background: none;
      box-shadow: none;
      font-weight: 300;
      color: white;
      width: 150px;
      margin-top: 40px;

      &:hover {
        color: $grey;
        background-color: $green;
        font-weight: 600;
      }
    }
  }
  &__change-password-form {
    display: none;
    label {
      display: block;
      margin-top: 20px;
      text-align: center;
    }
  }

  &__change-password-input {
    background: #333333;
    border: none;
    width: 100%;
    display: block;
    margin: 10px auto 10px;
    font-size: 1.4rem;
    border-radius: 10px;
    color: white;
    padding: 5px 10px;
  }
  &__change-password-input-sent {
    display: block;
    margin:  0 auto;
  }
}
