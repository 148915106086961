@import './modules/variables';
@import "./modules/_breakpoints.scss";
@import "./modules/_colors.scss";
@import "./partials/_layout.scss";
@import "./partials/_header.scss";
@import "./partials/_main-page.scss";
@import "./partials/_pricing.scss";
@import "./partials/_reservations.scss";
@import "./partials/_settings.scss";
@import "./partials/_regulations.scss";
@import "./partials/_footer.scss";
@import "./partials/_reservation.scss";
@import "./partials/_reservation-modal.scss";
@import "./partials/_registration.scss";


// bower:scss
// @import "../vendor/bootstrap/scss/bootstrap.scss";
// endbower

.custom-style
{
    color: #333;
}
